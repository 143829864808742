import { useState } from 'react';
import { isNil, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FilterBox from './FilterBox';
import DateFilter from './DateFilter';
import NumericFilter from './NumericFilter';
import { ContainsFilter } from './ContainsFilter';
import SelectFilter from './SelectFilter';
import ButtonWithIcon from '../buttons/button-with-icon/ButtonWithIcon';
import { ColumnType } from '../table/ColumnOptions';
import { AFTER, BEFORE, columnsMetadata, CONTAINS, GREATER_THAN, IS, IS_NOT, LESS_THAN } from './filterConsts';

import './filters.scss';

export default function Filters({ tableName, columns, getColumnOptions, getFiltersSelector, handleFiltersChange }) {
  const { t } = useTranslation(['common']);
  const [operatorsFields, setOperatorsFields] = useState([]);
  // used to open/close filters menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [operatorAnchorEl, setOperatorAnchorEl] = useState(null);
  const [valueAnchorEl, setValueAnchorEl] = useState(null);

  const fieldOpen = Boolean(anchorEl);
  const operatorOpen = Boolean(operatorAnchorEl);
  const valueOpen = Boolean(valueAnchorEl);

  // used to highlight selected filter
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);

  const filters = useSelector(getFiltersSelector);
  const columnsWithOptions = columns.filter(
    (column) => getColumnOptions(column)?.length || [ColumnType.DATE, ColumnType.NUMBER].includes(column.type),
  );

  const nonDateAvailableColumns = columnsWithOptions.filter((column) => column.type !== ColumnType.DATE);

  function handleClose(e, closeAll = false) {
    if (closeAll === true) {
      setAnchorEl(null);
      setOperatorAnchorEl(null);
      setValueAnchorEl(null);
    } else if (anchorEl) {
      if (operatorAnchorEl) {
        if (valueAnchorEl) {
          setValueAnchorEl(null);
        } else {
          setOperatorAnchorEl(null);
        }
      } else {
        setAnchorEl(null);
        setSelectedFilter(null);
      }
    }
  }

  function addFilter(type, column, value) {
    if (isNil(value)) {
      return;
    }
    const newFilters = uniqBy(
      [
        ...filters.filter((f) => !(f.column === column && f.type === type)),
        {
          column,
          value,
          type,
        },
      ],
      (filter) => `${filter.column}:${filter.type}=${filter.value}`,
    );
    handleFiltersChange(newFilters);
    handleClose(null, true);
  }

  function removeFilter(column, type, value) {
    const index = filters.findIndex((f) => f.column === column && f.type === type && f.value === value);
    if (index > -1) {
      const newFilters = filters.filter((f, i) => i !== index);
      handleFiltersChange(newFilters);
    }
  }

  function openFiltersMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  const currentOperator = operatorsFields[selectedOperation];

  // To force greaterThan and lessThan operators to use SelectFilter instead of NumericFilter in the severity filter
  const forceSelectFilter = columnsWithOptions[selectedFilter]?.forceSelectFilter;
  return (
    <div className="filters">
      <ButtonWithIcon
        icon={<FilterAltOutlinedIcon width={6} />}
        onClick={openFiltersMenu}
        text={t('tables.filters.addFilter')}
        color="blue"
        enabled={nonDateAvailableColumns.length > 0}
        dataTestId="add-filter-button"
      />

      <Menu
        // autoFocus={false}
        id="basic-menu"
        anchorEl={anchorEl}
        open={fieldOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem disabled>
          <div className="filter-title">{t('tables.filters.column')}</div>
        </MenuItem>
        {columnsWithOptions
          .filter((c) => !!c.Header.trim())
          .map((column, index) => (
            <MenuItem
              key={column.Header}
              className={index === selectedFilter ? 'selected' : ''}
              onClick={(e) => {
                if (index !== selectedFilter) {
                  setSelectedFilter(index);
                  setValueAnchorEl(null);
                  setSelectedOperation(null);
                }
                setOperatorAnchorEl(e.currentTarget.offsetParent.children[0]);
                // this is a patch for showing dropdown for insight name. change this when refactoring filters
                setOperatorsFields((columnsMetadata[column.Header] || columnsMetadata[column.backendKey]).operators);
              }}
            >
              <span>{column.Header}</span>
              <span>
                <KeyboardArrowRightIcon className="next-icon" />
              </span>
            </MenuItem>
          ))}
        <Menu
          autoFocus={false}
          id="operators-menu"
          anchorEl={operatorAnchorEl}
          open={operatorOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem disabled>
            <div className="filter-title">{columnsWithOptions[selectedFilter]?.Header}</div>
          </MenuItem>
          {operatorsFields.map((field, index) => (
            <MenuItem
              key={field}
              className={index === selectedOperation ? 'selected' : ''}
              onClick={(e) => {
                setValueAnchorEl(e.currentTarget.offsetParent.children[0]);
                setSelectedOperation(index);
              }}
            >
              <span>{t(`tables.filters.operators.${field}`)}</span>
              <span>
                <KeyboardArrowRightIcon className="next-icon" />
              </span>
            </MenuItem>
          ))}
          <Menu
            id="value-menu"
            anchorEl={valueAnchorEl}
            open={valueOpen}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem disabled>
              {(currentOperator === IS || currentOperator === IS_NOT || forceSelectFilter) && (
                <SelectFilter
                  onClose={(e) => handleClose(e, true)}
                  addFilter={addFilter}
                  options={getColumnOptions(columnsWithOptions[selectedFilter])}
                  column={columnsWithOptions[selectedFilter]?.backendKey}
                  operator={currentOperator}
                />
              )}
              {currentOperator === CONTAINS && (
                <ContainsFilter
                  onClose={(e) => handleClose(e, true)}
                  addFilter={addFilter}
                  operator={currentOperator}
                  column={columnsWithOptions[selectedFilter]?.backendKey}
                />
              )}
              {(currentOperator === GREATER_THAN || currentOperator === LESS_THAN) && !forceSelectFilter && (
                <NumericFilter
                  onClose={(e) => handleClose(e, true)}
                  addFilter={addFilter}
                  operator={currentOperator}
                  column={columnsWithOptions[selectedFilter]?.backendKey}
                />
              )}
              {(currentOperator === AFTER || currentOperator === BEFORE) && (
                <DateFilter
                  onClose={(e) => handleClose(e, true)}
                  addFilter={addFilter}
                  operator={currentOperator}
                  column={columnsWithOptions[selectedFilter]?.backendKey}
                />
              )}
            </MenuItem>
          </Menu>
        </Menu>
      </Menu>
      {filters.map((filter) => (
        <FilterBox
          tableName={tableName}
          key={filter.column}
          columnName={filter.column}
          value={filter.value}
          type={filter.type}
          closable={filter.closable ?? true}
          removeFilter={removeFilter}
        />
      ))}
    </div>
  );
}
