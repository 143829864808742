import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getTenantId, getTenants } from '../../services/AuthService';
import { getOrganizations, isSuperAdmin } from '../../redux/selector/authSelector';
import DropdownBox from '../../components/dropdown-box/DropdownBox';
import { changeTenant } from '../../services/OrgNameService';

export default function OrganizationPicker() {
  const { user: auth0User } = useAuth0();
  const [, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);
  const isSuperAdminRole = useSelector(isSuperAdmin);
  const { t } = useTranslation(['homePage']);

  // tenants can be fetched from the store if the user have just logged in, or from the local storage
  // if the user is already logged in and refreshed the page. We have to fetch it also from the store
  // because we need this component to re-render when the auth request returns
  const tenants = organizations || getTenants();

  // TODO uncomment this after the pentest
  // if (!tenants?.length || !auth0User || !isSuperAdminRole || !auth0User.email.includes('nokodsecurity.com')) {
  if (!tenants?.length || !auth0User || !isSuperAdminRole) {
    return null;
  }

  const currentTenantId = getTenantId();
  const currentTenant = tenants?.find((tenant) => tenant.name === currentTenantId);

  function handleClose(tenant) {
    setAnchorEl(null);
    if (tenant.name) {
      const newTenant = tenants?.find(({ name }) => name === tenant.name);
      if (newTenant && newTenant.name !== getTenantId()) {
        changeTenant(dispatch, newTenant);
      }
    }
  }

  return (
    <div className="organization">
      <DropdownBox
        width={240}
        text={t('menu.organization')}
        value={currentTenant?.display_name}
        selectedId={currentTenant?.id}
        menuItems={tenants.map((tenant) => ({
          id: tenant.id,
          onClick: () => handleClose(tenant),
          text: tenant.display_name,
        }))}
      />
    </div>
  );
}
