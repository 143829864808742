import { useDispatch, useSelector } from 'react-redux';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import ButtonWithIcon from '../../../components/buttons/button-with-icon/ButtonWithIcon';
import ActionModal from '../../../components/modal/ActionModal';
import { markInsightAsFalsePositive } from '../../../redux/slicers/insightSlicer';
import { isMarkFalsePositiveLoading, isMarkFalsePositiveSuccess } from '../../../redux/selector/insightsSelector';

export default function MarkAsFalsePositiveButton({ insightId }) {
  const { t } = useTranslation(['single-insight']);
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const loading = useSelector(isMarkFalsePositiveLoading);
  const success = useSelector(isMarkFalsePositiveSuccess);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <ActionModal
        open={modalOpen}
        closeModal={() => setModalOpen(false)}
        id="mark-as-false-positive"
        title={t('falsePositive.title')}
        onApprove={() => dispatch(markInsightAsFalsePositive({ insightId, email: user.email, name: user.name }))}
        loading={loading}
        toastSuccessful={success}
        toastTitle={t(`falsePositive.toastTitle${success ? 'Success' : 'Error'}`)}
      >
        <div>{t('falsePositive.description')}</div>
      </ActionModal>
      <ButtonWithIcon
        icon={<ThumbDownOffAltIcon />}
        text={t('falsePositive.buttonText')}
        onClick={() => setModalOpen(true)}
        color="red"
        filled
      />
    </div>
  );
}
