/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet } from '../../services/HttpService';
import { getTenantId, setRoles, setTenantId, setTenants } from '../../services/AuthService';
import { isAdmin, isSuperAdmin } from '../../consts/auth';

const initialState = {
  token: undefined,
  guest: {
    isGuest: false,
    permissions: [],
  },
  user: {
    loading: false,
    error: false,
    content: undefined,
  },
  organizations: {
    loading: false,
    error: false,
    content: undefined,
  },
  roles: {
    loading: false,
    error: false,
    content: undefined,
  },
  permissions: {
    loading: false,
    error: false,
    content: undefined,
  },
};

function retry(fn, args = [], retriesLeft = 10, interval = 1500) {
  return new Promise((resolve, reject) => {
    fn(...args)
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, args, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const fetchUser = createAsyncThunk('auth/getUser', async (userId) => {
  const res = await retry(doGet, [`users/${userId}`, false]);

  return res.data;
});

// the information must be retrieved together because requests can't be sent in HttpService without both of them
// If only one exists than requests may not be sent
export const fetchOrganizationsAndRoles = createAsyncThunk('auth/getOrganizationsAndRoles', async (userId) => {
  const [organization, roles, permissions] = await Promise.all([
    retry(doGet, [`users/${userId}/organizations`, { useTenant: false }]),
    retry(doGet, [`users/${userId}/roles`, { useTenant: false }]),
    retry(doGet, [`users/${userId}/permissions`, { useTenant: false }]),
  ]);

  return {
    organizations: organization.data,
    roles: roles.data,
    permissions: permissions.data,
  };
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.user.loading = true;
      state.user.error = false;
    });
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      state.user.loading = false;
      state.user.error = false;
      state.user.content = payload;
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.user.loading = false;
      state.user.error = true;
    });
    builder.addCase(fetchOrganizationsAndRoles.pending, (state) => {
      state.organizations.loading = true;
      state.organizations.error = false;

      state.roles.loading = true;
      state.roles.error = false;
    });
    builder.addCase(fetchOrganizationsAndRoles.fulfilled, (state, { payload }) => {
      const { roles, organizations } = payload;

      // TODO remove this, it's for the pentest
      const allowedOrganizations = organizations.filter((tenant) =>
        ['Onboarding Test Yuval', 'Nokod Power Platform'].includes(tenant.display_name),
      );

      // organizations
      state.organizations.loading = false;
      state.organizations.error = false;
      state.organizations.content = allowedOrganizations;

      const defaultTenant =
        organizations.find((org) => org.display_name.toLowerCase().includes('nokod power platform')) ||
        organizations[0];
      if (!getTenantId() && defaultTenant) {
        setTenantId(defaultTenant.name);
      }
      setTenants(allowedOrganizations);

      // roles
      state.roles.loading = false;
      state.roles.error = false;
      state.roles.content = roles;
      state.roles.isSuperAdmin = isSuperAdmin(roles);
      state.roles.isAdmin = isAdmin(roles);
      setRoles(roles);

      state.permissions.loading = false;
      state.permissions.error = false;
      state.permissions.content = payload.permissions;
    });
    builder.addCase(fetchOrganizationsAndRoles.rejected, (state) => {
      state.organizations.loading = false;
      state.organizations.error = true;

      state.roles.loading = false;
      state.roles.error = true;
    });
  },
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setIsGuest: (state, { payload }) => {
      state.guest.isGuest = payload;
    },
    setGuestPermissions: (state, { payload }) => {
      state.guest.permissions = payload;
    },
  },
});

// this is for configureStore
export const { setToken, setIsGuest, setGuestPermissions } = authSlice.actions;
export default authSlice.reducer;
